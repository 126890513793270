import Vue from 'vue';
import Vuex from 'vuex';
import { db } from '@/firebase/init';
import { auth } from '@/firebase/init';
import firebase, { firestore } from 'firebase';
import router from "../router/index";
// import VuexUndoRedo from 'vuex-undo-redo';

Vue.use(Vuex);
// Vue.use(VuexUndoRedo, { ignoreMutations: ['togglePopUp', 'setUser', 'SET_USER_DATA', 'SET_PROJECT', 'SET_MENU', 'SET_FOOTER', 'SET_PAGES', 'SET_CURRENT_PAGE', 'SET_BLOCKS', 'SET_ROWS', 'SET_INITIAL_ROWS', 'DELETE_ROW', 'UP_ROW', 'DOWN_ROW', 'LOADING_BUILDER', 'LOADING_BLOCKS', 'LOADING_CHANGES', 'SAVE_CHANGES', 'FILTER_ROWS', 'UPDATE_MENU', 'UPDATE_FOOTER'], });

export const store = new Vuex.Store({

    state: {
        user: null,
        user_data: {},
        overlay: false,
        project: {},
        pages: [],
        currentPage: {},
        menu: null,
        footer: null,
        blocks: {},
        rows: [],
        initialRows: [],
        leftPages: [],
        rightPages: [],
        products: [],
        popup: false,
        loadingBlocks: true,
        loadingBuilder: true,
        loadingChanges: false,
        progress: {},
        emptyRows: true,
        progressValue: 20,
        images: [],
        weeklyTutorials: [],
        currentWeek: {},
        currentChapter: {},
        modules: [],
        currentModule: {},
        globalProgress: 0,
        jsonBlocks: {},
        mainJson: {},
        courseAlmostComplete: null,
        courseComplete: null,
        modules_v2: [],
        currentModule2: {},
        chapter: {},
        lesson: {},
        progressModules: {},
        coaches: [],
        admins: [],
        coach: null,
        coachings: [],
        webinars: [],
        webinar: {},
        mainSidebar: true,
        tickets: [],
        ticket: {},
        partnerships: [],
        partnership: {},
        tasks: [],
        ressources: [],
        quizzes: [],
        courses: [],
        course_id: null,
        course: {},
        userCourseInfo: {},
        userCourseDoc: {},
        sessions: []
    },
    mutations: {

        togglePopUp(state) {
            state.popup = !state.popup;
        },
        SET_OVERLAY(state) {
            state.overlay = true;
            setTimeout(() => {
                state.overlay = false;
            }, 1800);
        },
        setUser(state, value) {
            state.user = value;
        },
        TOGGLE_MAIN_SIDEBAR(state) {
            state.mainSidebar = !state.mainSidebar;
        },
        SET_USER_DATA(state) {
            db.collection("users").doc(state.user.uid).get().then((doc) => {
                state.user_data = doc.data();
            })
        },
        SET_COACHES(state) {
            db.collection("coaches").onSnapshot((snapshot) => {
                state.coaches = [];
                snapshot.forEach((doc) => {
                    let coach = doc.data();
                    state.coaches.push(coach);
                })
            })
        },
        SET_ADMINS(state) {
            db.collection("admins").onSnapshot((snapshot) => {
                state.admins = [];
                snapshot.forEach((doc) => {
                    let admin = doc.data();
                    state.admins.push(admin);
                })
            })
        },
        SET_COACH(state, value) {
            db.collection("coaches").doc(value).get().then((doc) => {
                state.coach = doc.data();
            })
        },
        SET_COACHINGS(state, value) {
            db.collection(`users/${state.user_data.profile.user_id}/courses/${value}/coachings`).orderBy("id", "desc").onSnapshot((snapshot) => {
                state.coachings = [];
                snapshot.forEach((doc) => {
                    let coaching = doc.data();
                    state.coachings.push(coaching);
                })
            })
        },
        SET_PROJECT(state) {
            let projects = [];
            db.collection(`users/${state.user.uid}/project/`).onSnapshot(snap => {
                snap.forEach(doc => {
                    projects = [];
                    let project = doc.data();
                    projects.push(project);
                });
                state.project = projects[0];
                state.progress = state.project.progress;
            });
        },
        SET_MENU(state) {
            state.menu = state.project.menu
        },
        SET_FOOTER(state) {
            state.footer = state.project.footer
        },

        SET_PAGES(state) {

            let refPages = db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages`);

            refPages.orderBy("index").onSnapshot(snap => {
                state.pages = [];
                snap.forEach(doc => {
                    let page = doc.data();
                    state.pages.push(page);
                });

            });
        },
        UPDATE_PAGES_ORDER(state, payload) {
            let refPages = db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages`);

            refPages.where("slug", "==", payload.slug).get().then((snapshot) => {
                snapshot.forEach((doc) => {
                    doc.ref.update({
                        index: payload.index,
                    })
                })
            })
        },
        SET_CURRENT_PAGE(state, value) {
            if(value){
                db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages`).where("slug", "==", value).get().then((query) => {
                    const document = query.docs[0];
                    state.currentPage = document.data();
                    // snap.forEach((doc) => {
                    //     state.currentPage = doc.data();
                    // })
    
                });
            }

            else {
                state.currentPage = {}
            }
          
        },
        SET_IMAGES(state) {
            db.collection(`users/${state.user.uid}/project/`).onSnapshot(snap => {
                snap.forEach(doc => {
                    state.images = doc.data().images;
                });

            });
        },
        SAVE_IMAGE(state, value) {
            db.collection(`users/${state.user.uid}/project`)
                .doc(state.project.slug)
                .update({
                    images: firebase.firestore.FieldValue.arrayUnion(value),
                })
        },
        DELETE_IMAGE(state, value) {
            db.collection(`users/${state.user.uid}/project`)
                .doc(state.project.slug)
                .update({
                    images: firebase.firestore.FieldValue.arrayRemove(value),
                })
        },
        SET_BLOCKS(state, payload) {
            let ref = db.collection('blocks').where("id", "==", payload);
            ref.get().then(snapshot => {
                snapshot.forEach((doc) => {
                    state.blocks = doc.data();
                })
            })
        },
        SET_ROWS(state) {
            let ref = db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages/`).where("slug", "==", `${state.currentPage.slug}`);
            ref.get().then(snapshot => {
                snapshot.forEach((doc) => {
                    state.rows = doc.data().rows;

                });
            });
        },
        SET_INITIAL_ROWS(state) {
            state.initialRows = state.rows
        },
        SET_EMPTY_ROWS(state) {

            const empty = page => page.rows.length <= 1

            if (state.pages.some(empty)) {
                state.emptyRows = true
            }
            else {
                state.emptyRows = false
            }
        },
        ADD_ROW(state, payload) {
            state.rows.push({
                item: { id: payload.id, src: payload.src, type: payload.type, txt: payload.txt }
            });
        },
        DELETE_ROW(state, payload) {
            state.rows.splice(payload, 1);
        },
        DUPLICATE_ROW(state, payload) {
            let item = state.rows[payload];
            state.rows.splice(payload + 1, 0, item);
        },
        UP_ROW(state, payload) {
            let item = state.rows[payload];
            if (payload != 0) {
                state.rows.splice(payload, 1);
                state.rows.splice(payload - 1, 0, item);
            }

        },
        DOWN_ROW(state, payload) {
            let item = state.rows[payload];
            if (payload != state.rows.length - 1) {
                state.rows.splice(payload, 1);
                state.rows.splice(payload + 1, 0, item);
            }
        },
        LOADING_BUILDER(state, payload) {
            setTimeout(() => {
                state.loadingBuilder = payload;
            }, 800);

        },
        LOADING_BLOCKS(state, payload) {
            state.loadingBlocks = payload;
        },
        LOADING_CHANGES(state, payload) {
            state.loadingChanges = payload;


        },
        SAVE_CHANGES(state) {
            if (state.currentPage.slug) {
                db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages/`).where("slug", "==", state.currentPage.slug).get().then((query) => {
                    const document = query.docs[0];
                    document.ref.update({
                        rows: state.rows
                    }).catch(err => {
                        console.log(err);
                    })
                })
            }


        },
        FILTER_ROWS(state) {
            let filteredRows = [];
            let equivalentBlocks = [];

            state.rows.forEach((row) => {
                if (row.item.id.substring(0, 3) == "hea") {
                    state.blocks.headers.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }
                else if (row.item.id.substring(0, 3) == "med") {
                    state.blocks.medias.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "tam") {
                    state.blocks.textAndMedias.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "bou") {
                    state.blocks.buttons.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "tea") {
                    state.blocks.team.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "new") {
                    state.blocks.newsletter.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "con") {
                    state.blocks.contact.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "faq") {
                    state.blocks.faq.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "par") {
                    state.blocks.partnerships.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "blo") {
                    state.blocks.blog.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "pri") {
                    state.blocks.pricing.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 3) == "avi") {
                    state.blocks.testimony.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 4) == "text") {
                    state.blocks.texts.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

                else if (row.item.id.substring(0, 4) == "prod") {
                    state.blocks.products.forEach((block) => {
                        if (block.id == row.item.id) {
                            equivalentBlocks.push(block)
                        }
                    })
                }

            })

            equivalentBlocks.forEach((block) => {
                filteredRows.push({ item: block })
            })

            state.rows = filteredRows;
        },
        UPDATE_MENU(state, payload) {
            state.menu = payload;

            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                menu: state.menu
            }).catch(err => {
                console.log(err);
            });
        },
        UPDATE_FOOTER(state, payload) {
            state.footer = payload;

            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                footer: state.footer
            }).catch(err => {
                console.log(err);
            });
        },
        SET_PAGE_TO_BE_INSTALLED(state, payload) {
            db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages`).where("slug", "==", state.currentPage.slug).get().then((query => {
                const document = query.docs[0];
                document
                    .ref
                    .update({
                        toBeInstalled: payload
                    }).catch(err => {
                        console.log(err);
                    });
            }))
        },
        SET_PAGE_TO_BE_IN_MENU(state, payload) {
            db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages`).where("slug", "==", state.currentPage.slug).get().then((query) => {
                const document = query.docs[0];
                document
                    .ref
                    .update({
                        showInMenu: payload
                    }).catch(err => {
                        console.log(err);
                    });
            })
        },
        SET_PAGE_TO_BE_IN_FOOTER(state, payload) {
            db.collection(`users/${state.user.uid}/project/${state.project.slug}/pages`).where("slug", "==", state.currentPage.slug).get().then((query) => {
                const document = query.docs[0];
                document
                    .ref
                    .update({
                        showInFooter: payload
                    }).catch(err => {
                        console.log(err);
                    });
            })
        },
        UPDATE_LOGO(state, payload) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                logo: payload
            })
        },
        UPDATE_DOMAIN_REGISTRATION(state, payload) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                "progress.domain.registered": payload.value,
                "progress.domain.address": payload.address
            })
        },
        UPDATE_WORDPRESS_INSTALLED(state, payload) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                "progress.domain.wordpressInstalled": payload
            })
        },
        UPDATE_ALL_TEMPLATES_READY(state, payload) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                "progress.AllTemplatesReady": payload
            })
        },
        INCREASE_PROGRESS_VALUE(state, payload) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                "progress.progressValue": state.progress.progressValue += payload
            })
        },
        UPDATE_USER_PROFILE(state) {
            db.collection("users").doc(state.user.uid).update({
                "profile": state.user_data.profile
            });
        },
        UPDATE_USER_PROJECT(state) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                name: state.project.name

            });
        },
        UPDATE_SHORT_USER_PROJECT(state) {
            db.collection(`users`).doc(state.user.uid).update({
                "project": state.user_data.project

            });
        },
        UPDATE_PROFILE_PICTURE(state, payload) {
            db.collection("users").doc(state.user.uid).update({
                "profile.picture": payload
            });
        },
        UPDATE_WORDPRESS_CREDENTIALS(state, payload) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                "progress.wordpress.id": payload.id,
                "progress.wordpress.password": payload.password
            })
        },
        UPDATE_WEBSITE_INSTALLATION_STATUS(state, payload) {

            db.collection(`users`).doc(state.user.uid).update({
                "configuration.status": payload
            });

        },
        UPDATE_PROFILE(state, payload) {
            db.collection(`users/${state.user.uid}/project/`).doc(state.project.slug).update({
                name: payload.projectName
            }).then(() => {
                db.collection(`users/`).doc(state.user.uid).update({
                    name: payload.name,
                    tel: payload.tel
                });
            });
        },
        UPDATE_EMAIL(state, payload) {
            var user = auth.currentUser;
            db.collection(`users/`).doc(state.user.uid).update({
                "profile.email": payload
            }).then(() => {
                user.updateEmail(payload).then(() => {
                    console.log("successfully updated email in firebase auth")
                })
                    .catch(err => {
                        console.log(err.message)
                    });
            });

        },
        SET_WEEKLY_TUTORIALS(state) {
            db.collection(`users/${state.user.uid}/weeklyTutorials`).orderBy("index").onSnapshot(snap => {
                state.weeklyTutorials = [];
                snap.forEach((doc) => {
                    let week = doc.data();
                    state.weeklyTutorials.push(week);
                });
            });
        },
        SET_GLOBAL_PROGRESS(state) {
            let chaptersCompleted = 0;
            let tutorials = [];

            tutorials = state.weeklyTutorials;
            tutorials.forEach((week) => {
                week.chapters.forEach((chapter) => {
                    if (chapter.progress >= 100) {
                        chaptersCompleted++
                    }
                })
            });

            if (state.user_data.type === "e-commerce") {
                state.globalProgress = (chaptersCompleted / 20) * 100;
            }
            else {
                state.globalProgress = (chaptersCompleted / 17) * 100;
            }




        },
        SET_CURRENT_WEEK(state, payload) {
            db.collection(`users/${state.user.uid}/weeklyTutorials`)
                .where("title", "==", `Semaine ${payload}`)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        state.currentWeek = doc.data();
                    });
                });
        },
        LOAD_CURRENT_WEEK_META(state, payload) {
            db.collection(`users/${state.user.uid}/weeklyTutorials`)
                .where("id", "==", payload.week)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        state.currentWeek = doc.data();
                        state.currentChapter = state.currentWeek.chapters[payload.chapter];
                        state.modules = state.currentChapter.modules;
                        state.currentModule = state.modules[payload.module];

                    });
                });

        },
        LOAD_CURRENT_CHAPTER(state, payload) {
            state.currentChapter = state.currentWeek.chapters[payload];
        },
        LOAD_CHAPTER_MODULES(state) {
            state.modules = state.currentChapter.modules
        },
        LOAD_CURRENT_MODULE(state, payload) {
            state.currentModule = state.modules[payload];
        },
        UPDATE_MODULE_READ(state, payload) {
            let averagePurcentage = (100 / state.currentChapter.modules.length);

            if (payload) {
                state.currentChapter.progress += averagePurcentage
            }
            else {
                state.currentChapter.progress -= averagePurcentage
            }
            db.collection(`users/${state.user.uid}/weeklyTutorials`)
                .doc(state.currentWeek.id)
                .update({
                    chapters: state.currentWeek.chapters
                });
        },
        COMPLETE_CURRENT_WEEK(state) {
            db.collection(`users/${state.user.uid}/weeklyTutorials`)
                .doc(state.currentWeek.id)
                .update({
                    progress: 100
                });
        },
        SET_MAIN_JSON(state) {
            db.collection("json").doc("main").get().then((doc) => {
                state.mainJson = doc.data();
            });
        },
        SET_JSON_BLOCKS(state) {
            db.collection("json").doc("blocks").get().then((doc) => {
                state.jsonBlocks = doc.data();
            });
        },
        SEND_ADMIN_NOTIFICATION(state) {
            const today = new Date().toLocaleString();
            db.collection("adminNotifications").doc(state.user.uid).set({
                date: today,
                name: state.user_data.profile.name,
                email: state.user_data.profile.email,
                user_id: state.user.uid,
                read: false
            })
        },
        GET_COURSE_ALMOST_COMPLETE(state) {
            db.collection("users").doc(state.user.uid).get().then((doc) => {
                state.courseAlmostComplete = doc.data().courseAlmostComplete;
            })
        },
        SET_COURSE_ALMOST_COMPLETE(state) {
            state.courseAlmostComplete = true;
            db.collection("users").doc(state.user_data.profile.user_id).update({
                courseAlmostComplete: true
            })
        },
        GET_COURSE_COMPLETE(state) {
            db.collection("users").doc(state.user.uid).get().then((doc) => {
                state.courseComplete = doc.data().courseComplete;
            })
        },
        SET_COURSE_COMPLETE(state) {
            state.courseComplete = true;
            db.collection("users").doc(state.user_data.profile.user_id).update({
                courseComplete: true
            })
        },
        SET_COURSES(state) {
            db.collection("/courses").orderBy("index").get().then((snap) => {
                state.courses = [];
                snap.forEach((doc) => {
                    let course = doc.data();
                    state.courses.push(course);
                })
            })
        },
        SET_USER_COURSE_INFO(state, payload) {
            db.collection(`users/${state.user.uid}/courses`)
                .doc(payload)
                .onSnapshot((doc) => {
                    state.userCourseInfo = doc.data().project;
                })
            state.userCourseInfo = state.user_data.coursesSummary.find((c) => c.id === payload)
        },
        SET_SESSIONS(state, payload) {
            db.collection(`globalSessions/${payload}/sessions`).orderBy('start', 'desc').onSnapshot(snap => {
                state.sessions = [];

                snap.forEach((doc) => {
                    let session = doc.data();
                    state.sessions.push(session);

                });
            });
        },
        SET_COURSE(state, value) {
            db.collection("courses").doc(value).get().then((doc) => {
                state.course = doc.data();
            })
        },
        SET_USER_COURSE_DOC(state, value) {
            db.collection(`users/${state.user_data.profile.user_id}/courses/`).doc(value).get().then((doc) => {
                state.userCourseDoc = doc.data();
            })
        },
        UPDATE_STATIC_GLOBAL_PROGRESS(state, value) {
            db.collection(`users/${state.user_data.profile.user_id}/courses/`).doc(value.course).update({
                "project.staticGlobalProgress": value.progress
            })
        },
        SET_COURSE_ID(state, value) {
            state.course_id = value;
        },
        SET_MODULES_V2(state, value) {
            if (state.user_data.project.selectedPackage != 3) {
                db.collection(`/courses/${value}/modules`).where("id", "!=", "CQ0HIBSCA5MmioCroZ2k").get().then(snap => {
                    state.modules_v2 = [];
                    snap.forEach((doc) => {
                        let module = doc.data();
                        state.modules_v2.push(module);
                    });
                }).then(() => {
                    state.modules_v2.sort((a, b) => (a.index > b.index) ? 1 : -1)
                })

            }
            else {
                db.collection(`/courses/${value}/modules`).orderBy("index").get().then(snap => {
                    state.modules_v2 = [];
                    snap.forEach((doc) => {
                        let module = doc.data();
                        state.modules_v2.push(module);
                    });
                });
            }
        },
        LOAD_CHAPTER(state, payload) {
            state.chapter = state.modules_v2[payload.module].chapters[payload.chapter];
        },
        SET_PROGRESS_MODULES(state, value) {
            db.collection(`users/${state.user_data.profile.user_id}/courses/${value}/progressModules`).orderBy("index").onSnapshot(snap => {
                state.progressModules = [];
                snap.forEach((doc) => {
                    let progressModule = doc.data();
                    state.progressModules.push(progressModule);
                });
            });
        },
        SET_MODULE_CHAPTER_LESSON(state, payload) {
            state.currentModule2 = state.modules_v2[payload.module - 1];
            state.chapter = state.currentModule2.chapters[payload.chapter - 1];
            state.lesson = state.chapter.lessons[payload.lesson - 1];
        },
        COMPLETE_LESSON(state, payload) {
            let refProgress = db.collection(`users/${state.user_data.profile.user_id}/courses/${payload.course}/progressModules`);
            refProgress.where("index", "==", parseInt(payload.module)).get().then((snapshot) => {
                snapshot.forEach((document) => {
                    document.ref.update({
                        [`chapter_${payload.chapter}.lesson_${payload.lesson}`]: true
                    })
                })
            })
        },
        SET_WEBINARS(state) {
            db.collection(`webinars`).orderBy("date", "desc").onSnapshot(snap => {
                state.webinars = [];
                snap.forEach((doc) => {
                    let webinar = doc.data();
                    state.webinars.push(webinar);
                });
            });
        },
        SET_WEBINAR(state, payload) {
            db.collection("webinars").doc(payload.id).get().then((doc) => {
                state.webinar = doc.data();
            });
        },
        SET_TICKETS(state) {
            db.collection("tickets")
                .where("user_id", "==", state.user.uid)
                .get()
                .then((snapshot) => {
                    state.tickets = []

                    snapshot.forEach((doc) => {
                        let ticket = doc.data();
                        state.tickets.push(ticket)

                    });
                });

        },
        ADD_TICKET(state, payload) {
            db.collection("tickets").add(payload).then((doc) => {
                db.collection("tickets").doc(doc.id).update({
                    id: doc.id,
                    created: firestore.FieldValue.serverTimestamp()
                }).then(() => {
                    db.collection("tickets").doc(doc.id).get().then((document) => {
                        state.ticket = document.data();
                        router.push({ name: 'ticket', params: { id: doc.id } })
                    })
                })
            })
        },
        SET_TICKET(state, payload) {
            db.collection("tickets").doc(payload).get().then((doc) => {
                state.ticket = doc.data();
            })
        },
        CLOSE_TICKET(state) {
            db.collection("tickets").doc(state.ticket.id).update({
                status: "closed"
            })
        },
        UPDATE_TICKET_MESSAGES(state, payload) {
            db.collection("tickets").doc(payload).update({
                messages: state.ticket.messages,
                status: "pending"
            })
        },
        SET_PARTNERSHIPS(state) {
            db.collection("partnerships")
            .orderBy("score", "desc")
            .get()
                .then((snapshot) => {
                    state.partnerships = []

                    snapshot.forEach((doc) => {
                        let partnership = doc.data();
                        state.partnerships.push(partnership)

                    });
                });


        },
        SET_PARTNERSHIP(state, payload) {
            db.collection("partnerships")
                .doc(payload)
                .get()
                .then((doc) => {
                    state.partnership = doc.data();
                });

        },
        SET_TASKS(state, value) {
            db.collection(`users/${state.user.uid}/courses/${value}/tasks`).orderBy("index").get().then((snap) => {
                state.tasks = []

                snap.forEach((doc) => {
                    let task = doc.data();
                    state.tasks.push(task)

                });
            })
        },
        UPDATE_TASKS(state, value) {
            db.collection(`users/${state.user.uid}/courses/${value.course}/tasks`).doc(value.id).update({
                "state": value.state
            })
        },
        ADD_TASKS_PROP(state) {
            db.collection("users").doc(state.user_data.profile.user_id).update({
                "project.tasks": [
                    {
                        title: "Bien démarrer ma formation !",
                        subtitle:
                            "Quelques premières étapes pour démarrer sereinement votre formation 🚀",
                        checklist: [
                            {
                                title: "Prendre son premier rendez-vous avec son coach",
                                done: true,
                            },
                            { title: "Créer mon projet dans la plateforme", done: true },
                            {
                                title:
                                    "Mes objectifs et date de fin de projet (facultatif) ont bien été définis avec mon coach",
                                done: true,
                            },
                        ],
                    },
                    {
                        title: "Préparer mon site avant la mise en ligne",
                        subtitle: "Bâtir de bonnes bases est la clé de la réussite 🤓",
                        checklist: [
                            {
                                title: "Définir et créer mes pages dans la plateforme",
                                done: false,
                            },
                            {
                                title: "Remplir mes maquettes OU choisir un template Divi",
                                done: false,
                            },
                            { title: "Regarder le Module 1 de la formation", done: false },
                            {
                                title:
                                    "Définir une première charte graphique pour mon site internet",
                                done: false,
                            },
                        ],
                    },
                    {
                        title: "Nom de domaine et hébergement",
                        subtitle: "Vérifier les éléments essentiels à mon site 🚨",
                        checklist: [
                            { title: "Regarder le Module 2 de la formation", done: false },
                            {
                                title: "Choisir un nom de domaine",
                                done: false,
                                text: "A cocher plus tard si vous avez opté pour un nom de domaine brouillon",
                                link: "https://webmyday.io/comment-bien-choisir-son-nom-de-domaine",
                            },
                            {
                                title: "Souscrire à un plan d'hébergement",
                                done: false,
                                text: "A cocher plus tard si vous avez opté pour un nom de domaine brouillon",
                                link: "https://webmyday.io/heberger-mon-site-wordpress-astuces",
                            },
                            {
                                title: "Installer WordPress",
                                done: false,
                                text: "A cocher plus tard si vous avez opté pour un nom de domaine brouillon",
                                link: "https://webmyday.io/comment-installer-wordpress-simplement",
                            },
                            {
                                title:
                                    "Effectuer le transfert de mon nom de domaine brouillon vers nom de domaine définitif",
                                done: false,
                            },
                            {
                                title: `Je prends un coaching avec mon coach pour vérifier mes maquettes et ma configuration`,
                                done: false,
                            },
                        ],
                    },
                    {
                        title: "Configurer mon site",
                        subtitle:
                            "Il est temps de demander la configuration du site à l'équipe 🤖",
                        checklist: [
                            {
                                title:
                                    "Demander la construction de mon site à l'équipe Webmyday",
                                done: false,
                                link: "/installer-mon-site",
                                btn: {
                                    name: "installation",
                                    text: "Demander la configuration",
                                },
                            },
                            {
                                title:
                                    "Je réserve un second coaching avec mon coach pour faire le point !",
                                done: false,
                            },
                            {
                                title: "Regarder Module 3 de la formation",
                                done: false,
                            },
                        ],
                    },
                    {
                        title: "Avancement sur mon projet",
                        subtitle:
                            "Ici apparaitrons les différents points à réaliser pour finaliser votre projet avec l'aide de votre coach 💻",
                        checklist: [],
                    },
                ]
            })
        },
        SET_RESSOURCES(state, value) {
            db.collection(`/courses/${value}/ressources`)
                .get()
                .then((snapshot) => {
                    state.ressources = []

                    snapshot.forEach((doc) => {
                        let ressource = doc.data();
                        state.ressources.push(ressource)

                    });
                });


        },
        SET_QUIZZES(state, value) {
            db.collection(`/courses/${value}/short_quizzes`)
            .orderBy("index")
                .get()
                .then((snapshot) => {
                    state.quizzes = []

                    snapshot.forEach((doc) => {
                        let quizz = doc.data();
                        state.quizzes.push(quizz)

                    });
                });


        }



    },
    actions: {
        setUserData: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_USER_DATA')
                    resolve()
                }, 250);
            })
        },
        setProject: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PROJECT')
                    resolve()
                }, 300);
            })
        },
        setMenu: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_MENU')
                    resolve()
                }, 200);
            })
        },
        setFooter: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_FOOTER')
                    resolve()
                }, 200);
            })
        },
        setPages: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PAGES')
                    resolve()
                }, 200);
            })
        },
        updatePagesOrder: ({ commit }, value) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_PAGES_ORDER', value)
                    resolve()
                }, 200);
            })
        },
        setCurrentPage: ({ commit }, value) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_CURRENT_PAGE', value)
                    resolve()
                }, 200);
            })
        },
        setBlocks: ({ commit }, value) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_BLOCKS', value)
                    resolve()
                }, 300);
            })
        },
        setRows: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_ROWS')
                    resolve()
                }, 300);
            })
        },
        setInitialRows: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_INITIAL_ROWS')
                    resolve()
                }, 300);
            })
        },
        setEmptyRows: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_EMPTY_ROWS')
                    resolve()
                }, 300);
            })
        },
        filterRows: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('FILTER_ROWS')
                    resolve()
                }, 300);
            })
        },
        addRow: (context, payload) => {
            context.commit('ADD_ROW', payload);
        },
        deleteRow: (context, payload) => {
            context.commit('DELETE_ROW', payload);
        },
        duplicateRow: (context, payload) => {
            context.commit('DUPLICATE_ROW', payload);
        },
        upRow: (context, payload) => {
            context.commit('UP_ROW', payload);
        },
        downRow: (context, payload) => {
            context.commit('DOWN_ROW', payload);
        },
        loadingBuilder: (context, payload) => {
            context.commit('LOADING_BUILDER', payload);
        },
        loadingBlocks: (context, payload) => {
            context.commit('LOADING_BLOCKS', payload);
        },
        loadingChanges: (context, payload) => {
            context.commit('LOADING_CHANGES', payload);
        },
        saveChanges: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SAVE_CHANGES')
                    resolve()
                }, 300)
            })
        },
        updateMenu: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_MENU', payload)
                    resolve()
                }, 100)
            })
        },
        updateFooter: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_FOOTER', payload)
                    resolve()
                }, 100)
            })
        },
        updateLogo: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_LOGO', payload)
                    resolve()
                }, 100)
            })
        },
        setPageToBeInstalled: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PAGE_TO_BE_INSTALLED', payload)
                    resolve()
                }, 250)
            })
        },
        setPageToBeInMenu: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PAGE_TO_BE_IN_MENU', payload)
                    resolve()
                }, 250)
            })
        },
        setPageToBeInFooter: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PAGE_TO_BE_IN_FOOTER', payload)
                    resolve()
                }, 250)
            })
        },
        updateDomainRegistration: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_DOMAIN_REGISTRATION', payload)
                    resolve()
                }, 250)
            })
        },
        updateWordpressInstalled: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_WORDPRESS_INSTALLED', payload)
                    resolve()
                }, 250)
            })
        },
        updateAllTemplatesReady: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_ALL_TEMPLATES_READY', payload)
                    resolve()
                }, 250)
            })
        },
        increaseProgressValue: (context, payload) => {
            context.commit('INCREASE_PROGRESS_VALUE', payload);
        },
        updateProfilePicture: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_PROFILE_PICTURE', payload)
                    resolve()
                }, 250)
            })
        },
        updateWordpressCredentials: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_WORDPRESS_CREDENTIALS', payload)
                    resolve()
                }, 250)
            })
        },
        updateWebsiteInstallationStatus: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_WEBSITE_INSTALLATION_STATUS', payload)
                    resolve()
                }, 250)
            })
        },
        updateProfile: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_PROFILE', payload)
                    resolve()
                }, 250)
            })
        },
        updateEmail: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_EMAIL', payload)
                    resolve()
                }, 250)
            });
        },
        saveImage: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SAVE_IMAGE', payload)
                    resolve()
                }, 250)
            });
        },
        deleteImage: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('DELETE_IMAGE', payload)
                    resolve()
                }, 250)
            });
        },
        setWeeklyTutorials: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_WEEKLY_TUTORIALS')
                    resolve()
                }, 250)
            });
        },
        setCurrentWeek: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_CURRENT_WEEK', payload)
                    resolve()
                }, 250)
            });
        },
        loadCurrentWeekMeta: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('LOAD_CURRENT_WEEK_META', payload)
                    resolve()
                }, 250)
            });
        },
        loadCurrentChapter: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('LOAD_CURRENT_CHAPTER', payload)
                    resolve()
                }, 250)
            });
        },
        loadChapterModules: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('LOAD_CHAPTER_MODULES')
                    resolve()
                }, 250)
            });
        },
        loadCurrentModule: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('LOAD_CURRENT_MODULE', payload)
                    resolve()
                }, 100)
            });
        },
        updateModuleRead: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_MODULE_READ', payload)
                    resolve()
                }, 250)
            });
        },
        completeCurrentWeek: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('COMPLETE_CURRENT_WEEK')
                    resolve()
                }, 250)
            });
        },
        setGlobalProgress: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_GLOBAL_PROGRESS')
                    resolve()
                }, 350)
            });
        },
        getCourseAlmostComplete: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('GET_COURSE_ALMOST_COMPLETE')
                    resolve()
                }, 250)
            });
        },
        setCourseAlmostComplete: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COURSE_ALMOST_COMPLETE')
                    resolve()
                }, 250)
            });
        },
        getCourseComplete: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('GET_COURSE_COMPLETE')
                    resolve()
                }, 250)
            });
        },
        setCourseComplete: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COURSE_COMPLETE')
                    resolve()
                }, 250)
            });
        },
        setMainJson: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_MAIN_JSON')
                    resolve()
                }, 250)
            });
        },
        setJsonBlocks: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_JSON_BLOCKS')
                    resolve()
                }, 250)
            });
        },
        sendAdminNotification: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SEND_ADMIN_NOTIFICATION')
                    resolve()
                }, 250)
            });
        },
        setModulesV2: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_MODULES_V2', payload)
                    resolve()
                }, 250)
            });
        },
        loadChapter: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('LOAD_CHAPTER', payload)
                    resolve()
                }, 250)
            });
        },
        setProgressModules: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PROGRESS_MODULES', payload)
                    resolve()
                }, 300)
            });
        },
        setModuleChapterLesson: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_MODULE_CHAPTER_LESSON', payload)
                    resolve()
                }, 250)
            });
        },
        completeLesson: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('COMPLETE_LESSON', payload)
                    resolve()
                }, 200)
            });
        },
        setCoaches: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COACHES')
                    resolve()
                }, 250);
            })
        },
        setAdmins: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_ADMINS')
                    resolve()
                }, 250);
            })
        },
        setCoach: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COACH', payload)
                    resolve()
                }, 200);
            })
        },
        setCoachings: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COACHINGS', payload)
                    resolve()
                }, 250);
            })
        },
        setWebinars: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_WEBINARS')
                    resolve()
                }, 150);
            })
        },
        setWebinar: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_WEBINAR', payload)
                    resolve()
                }, 150);
            })
        },
        setTickets: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_TICKETS')
                    resolve()
                }, 150);
            })
        },
        addTicket: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('ADD_TICKET', payload)
                    resolve()
                }, 150);
            })
        },
        setTicket: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_TICKET', payload)
                    resolve()
                }, 150);
            })
        },
        closeTicket: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('CLOSE_TICKET')
                    resolve()
                }, 150);
            })
        },
        updateTicketMessages: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_TICKET_MESSAGES', payload)
                    resolve()
                }, 250);
            })
        },
        updateUserProfile: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_USER_PROFILE')
                    resolve()
                }, 150);
            })
        },
        updateUserProject: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_USER_PROJECT')
                    resolve()
                }, 150);
            })
        },
        updateShortUserProject: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_SHORT_USER_PROJECT')
                    resolve()
                }, 150);
            })
        },
        setPartnerships: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PARTNERSHIPS')
                    resolve()
                }, 150);
            })
        },
        setPartnership: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_PARTNERSHIP', payload)
                    resolve()
                }, 150);
            })
        },
        setTasks: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_TASKS', payload)
                    resolve()
                }, 150);
            })
        },
        updateTasks: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_TASKS', payload)
                    resolve()
                }, 150);
            })
        },
        setRessources: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_RESSOURCES', payload)
                    resolve()
                }, 150);
            })
        },
        setQuizzes: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_QUIZZES', payload)
                    resolve()
                }, 150);
            })
        },
        setCourses: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COURSES')
                    resolve()
                }, 150);
            })
        },
        setCourseId: ({ commit }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COURSE_ID')
                    resolve()
                }, 150);
            })
        },
        setCourse: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_COURSE', payload)
                    resolve()
                }, 150);
            })
        },
        setUserCourseDoc: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_USER_COURSE_DOC', payload)
                    resolve()
                }, 150);
            })
        },
        updateStaticGlobalProgress: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('UPDATE_STATIC_GLOBAL_PROGRESS', payload)
                    resolve()
                }, 150);
            })
        },
        setUserCourseInfo: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_USER_COURSE_INFO', payload)
                    resolve()
                }, 150);
            })
        },
        setSessions: ({ commit }, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    commit('SET_SESSIONS', payload)
                    resolve()
                }, 150);
            })
        },

    }
});